<template>
  <div>
    <q-btn v-b-toggle.sidebar-1 
      label="체크리스트"
      size="lg"
      color="primary"
      class="sidebar-btn"
      :class="!visible ? '' : 'sidebar-show-btn'"
      :icon="!visible1 ? 'arrow_back_ios_new' : 'arrow_forward_ios'"
      :style="!visible ? 'right:-115px;transition: 0.35s;' : 'right:90%;transition: 0.35s;'" />
    <q-btn v-b-toggle.sidebar-2 
      label="수행계획서"
      size="lg"
      color="light-green"
      class="sidebar-btn"
      :class="!visible ? '' : 'sidebar-show-btn'"
      :icon="!visible2 ? 'arrow_back_ios_new' : 'arrow_forward_ios'"
      :style="!visible ? 'right:-115px;transition: 0.35s;' : 'right:90%;transition: 0.35s;'"
      style="top: 110px;" />
    <q-btn
      size="lg"
      color="red"
      class="sidebar-btn sidebar-close-btn"
      icon="close"
      :style="!visible ? 'right:0px;transition: 0.35s;' : 'right:90%;transition: 0.35s;'"
      style="top: 170px;"
      @click="allClose" />
    <b-sidebar 
      id="sidebar-1" 
      :title="sidebar1Title" 
      class="internal-result-sidebar1"
      width="90%"
      right shadow
      v-model="visible1">
      <div class="px-3 py-2">
        <component
          :is="sidebar1Components"
          :popupParam="popupParam"
          :action.sync="action"
          :disabled.sync="disabled"
        />
      </div>
    </b-sidebar>
    <b-sidebar 
      id="sidebar-2" 
      title="수행계획서 정보" 
      class="internal-result-sidebar2"
      width="90%"
      right shadow
      v-model="visible2">
      <div class="px-3 py-2">
        <component
          :is="sidebar2Components"
          :popupParam="popupParam"
          :action.sync="action"
          :disabled.sync="disabled"
        />
      </div>
    </b-sidebar>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      v-model="tab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :popupParam="popupParam"
          :action.sync="action"
          :tabItems.sync="tab.childrend"
          :result.sync="tab.result"
          :disabled.sync="disabled"
          :contentHeight="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-action',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      visible1: false,
      visible2: false,
      sidebar1Components: () => import(`${'../internalActionImprove.vue'}`),
      sidebar2Components: () => import(`${'../internalActionInfoSide.vue'}`),
      tab: '',
      tabItems: [
        // { name: 'actionPlan', icon: 'info', label: '수행계획서', class:'tab-orange', component: () => import(`${'./internalActionInfo.vue'}`) },
        // { name: 'checklist', icon: 'info', label: '사전준비(체크리스트) & 심사', component: () => import(`${'./internalActionChecklist.vue'}`) },
        // { name: 'improvement', icon: 'info', label: '시정조치', component: () => import(`${'./internalActionImprove.vue'}`) },
      ],
      action: {
        id: '',  // 일련번호
        plantCd: '',
        col1: '', // 심사구분
        col2: '', // 심사 준비 요청사항
        col3: '', // 심사 목적 및 범위
        regDtStr: '',
        regUserName: '',
        auditMonthDepts: [], // 심사일정 - 피심사팀에 따른
        auditTeams: [], // 심사팀
        distributeTeams: [], // 배포부서
        checklistResult: [],
      },
      
      draw:{
        style: {
          background: 'rgba(150, 5, 0, 0.2)',
          padding: '0 5px',
          borderRadius: '0',
          marginTop: '54px',
          marginRight: '15px',
          height: '100%',
          top: '0'
        }
      },
      editable: true,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    disabled() {
      return false;
    },
    visible() {
      return this.visible1 || this.visible2
    },
    sidebar1Title() {
      let data = this.$_.find(this.tabItems, { name: this.tab })
      return `${data ? data.label : ''} 체크리스트`
    }
  },
  watch: {
    'action.auditMonthDepts': {
      handler: function () {
        let _options = [];
        if (this.action.auditMonthDepts && this.action.auditMonthDepts.length > 0) {
          let _deptCdMap = this.$_.uniq(this.$_.map(this.action.auditMonthDepts, 'deptCd'))
          this.$_.forEach(_deptCdMap, deptCd => {
            let deptName = this.$_.find(this.action.auditMonthDepts, { deptCd: deptCd }).deptName;
            let filterdata = this.$_.filter(this.action.auditMonthDepts, { deptCd: deptCd });
            let _childrend = []
            this.$_.forEach(filterdata, item => {
              _childrend.push({
                key: uid(),
                name: `${item.date} ${item.times[0]} ~ ${item.times[1]}`,
                label: `${item.date} ${item.times[0]} ~ ${item.times[1]}`,
                component: () => import(`${'./internalResultInfo.vue'}`),
                result: {
                  date: `${item.date} ${item.times[0]} ~ ${item.times[1]}`,
                  deptName: deptName,
                  auditResult: item.auditResult,
                  auditErrorList: item.auditErrorList,
                  etc: item.etc,
                  list: item.list,
                  col1: item.col1,
                },
              })
            })
            _options.push({
              key: uid(),
              deptCd: deptCd,
              deptName: deptName,
              name: deptCd,
              label: deptName,
              icon: 'info',
              component: () => import(`${'./internalResultTab.vue'}`),
              childrend: _childrend,
              // class:'tab-orange',
            })
          })
        }
        if (_options && _options.length > 0) {
          this.$set(this.$data, 'tab', _options[0].name)
        }
        this.$set(this.$data, 'tabItems', _options)
      },
      deep: true,
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.mam.review.get.url;
      this.getDetail();
    },
    getDetail() {
      this.$http.url = selectConfig.sai.internal.action.checklistResult.list.url;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.action = {
          id: '1',  // 일련번호
          plantCd: '1000',
          col1: 'SIAT000001', // 심사구분
          col2: '① 적용되는 규격(ISO 45001) 요구사항\n'
            + '② 피심사팀에 관계되는 사내표준, 이해관계자(고객, 민원 등) 및 법규 요구사항\n'
            + '③ 과거 심사 시 지적사항\n'
            + '④ 피심사팀의 방침, 목표 및 추진계획 등 안전보건경영시스템의 실행 요구사항\n'
            + '⑤ 피심사팀의 주요 안전보건 관련 문제점 및 기타 사항', // 심사 준비 요청사항
          col3: '- 안전보건경영시스템 매뉴얼, 프로세스, 절차서, 지침서 등 표준문서 구비사항\n'
            + '- ISO 45001 요구사항, 표준문서, 관련법규 및 내부규정 등 이행사항 관련 기록\n'
            + '- 내부심사 체크리스트를 활용하여 본사 및 사업장의 이행사항 점검\n'
            + ' * 심사내용 중 부적합사항에 대한 시정조치계획 수립', // 심사범위
          regDtStr: '2022-01-01',
          regUserName: '관리자',
          auditMonthDepts: [
            { auditResult: '', auditErrorList: '', etc: '', list: [], date: '2022-01-01', times: ['08:00', '17:00'], id: '1', deptCd: 'D10002', deptName: '생산팀', col1: 'IATF16949:2016 요구사항 경영시스템 관리', remarks: '', },
            { auditResult: '', auditErrorList: '', etc: '', list: [], date: '2022-01-08', times: ['08:00', '17:00'], id: '2', deptCd: 'D10002', deptName: '생산팀', col1: 'IATF16949:2016 요구사항 개발', remarks: '', },
            { auditResult: '', auditErrorList: '', etc: '', list: [], date: '2022-01-14', times: ['08:00', '17:00'], id: '3', deptCd: 'D10002', deptName: '생산팀', col1: 'IATF16949:2016 요구사항 인적자원관리', remarks: '', },
            { auditResult: '', auditErrorList: '', etc: '', list: [], date: '2022-01-22', times: ['08:00', '17:00'], id: '4', deptCd: 'D10002', deptName: '생산팀', col1: 'IATF16949:2016 요구사항 구매 외주', remarks: '', },
            { auditResult: '', auditErrorList: '', etc: '', list: [], date: '2022-01-24', times: ['08:00', '17:00'], id: '5', deptCd: 'D10002', deptName: '생산팀', col1: '관리계획서 생산', remarks: '', },
            { auditResult: '', auditErrorList: '', etc: '', list: [], date: '2022-01-26', times: ['08:00', '17:00'], id: '6', deptCd: 'D10002', deptName: '생산팀', col1: '관리계획서 품질보증', remarks: '', },
            { auditResult: '', auditErrorList: '', etc: '', list: [], date: '2022-02-01', times: ['08:00', '17:00'], id: '7', deptCd: 'D10002', deptName: '생산팀', col1: '품질경영시스템 문서', remarks: '', },
            { auditResult: '', auditErrorList: '', etc: '', list: [], date: '2022-02-12', times: ['08:00', '17:00'], id: '8', deptCd: 'D10002', deptName: '생산팀', col1: '품질경영시스템 기록', remarks: '', },
            { auditResult: '', auditErrorList: '', etc: '', list: [], date: '2022-02-19', times: ['08:00', '17:00'], id: '9', deptCd: 'D10002', deptName: '생산팀', col1: '환경경영시스템 요구사항', remarks: '', },
            { auditResult: '', auditErrorList: '', etc: '', list: [], date: '2022-02-22', times: ['08:00', '17:00'], id: '10', deptCd: 'D10002', deptName: '생산팀', col1: '환경경영시스템 문서', remarks: '', },
            { auditResult: '', auditErrorList: '', etc: '', list: [], date: '2022-02-27', times: ['08:00', '17:00'], id: '11', deptCd: 'D10002', deptName: '생산팀', col1: '환경경영시스템 기록', remarks: '', },
            { auditResult: '', auditErrorList: '', etc: '', list: [], date: '2022-03-01', times: ['08:00', '17:00'], id: '12', deptCd: 'D10005', deptName: '생산팀 A', col1: '제조프로세스 심사 - 공정감사', remarks: '', },
            { auditResult: '', auditErrorList: '', etc: '', list: [], date: '2022-05-01', times: ['08:00', '17:00'], id: '13', deptCd: 'D10006', deptName: '생산팀 B', col1: '제품심사 - 최종제품감사', remarks: '', },
          ], // 심사일정 - 피심사팀에 따른
          distributeTeams: [
            { deptCd: 'D10003', deptName: '안전환경팀', },
            { deptCd: 'D10004', deptName: '기술공정팀', },

          ], // 배포부서
          auditTeams: [
            { deptCd: 'D10003', deptName: '안전환경팀', userId: 'U000000018', userName: '김이름', roleCd: '1', roleName: '심사팀장', },
            { deptCd: 'D10003', deptName: '안전환경팀', userId: 'U000000019', userName: '이이름', roleCd: '2', roleName: '심사자', },
            { deptCd: 'D10003', deptName: '안전환경팀', userId: 'U000000020', userName: '최이름', roleCd: '2', roleName: '심사자', },
          ], // 심사팀
          checklistResult: _result.data,
        };
      },);
      // if (this.popupParam.mdmChemReviewId) {
      //   this.$http.url = this.$format(this.detailUrl, this.popupParam.mdmChemReviewId);
      //   this.$http.type = 'GET';
      //   this.$http.request((_result) => {
      //     this.review = _result.data;
      //   },);
      // } else {
      //   // 신규등록인 경우
      //   this.$set(this.review, 'reviewRequestDate', this.$comm.getCalculatedDate(
      //     this.$comm.getToday(),
      //     '7d',
      //     'YYYY-MM-DD',
      //     'YYYY-MM-DD')
      //   )
      //   this.$set(this.review, 'plantCds', this.$store.getters.user.plantCd);
      //   this.$set(this.review, 'mdmChemReviewStepCd', 'MCR0000001'); // 직성중
      // }
    },
    closePopup() {
      this.$emit('closePopup')
    },
    tabClick(tab) {
      this.$set(this.$data, 'tab', tab.name)
    },
    allClose() {
      this.visible1 = false;
      this.visible2 = false;
    }
  }
};
</script>
<style lang="sass">
.internal-result-sidebar1
  .b-sidebar
    height: 70vh
.internal-result-sidebar2
  .b-sidebar
    height: 50vh

.sidebar-btn
  position: fixed
  z-index: 8000
  opacity: 0.8
  &:hover 
    right:0px
    transition: 0.5
  
.sidebar-btn:not(.sidebar-close-btn, .sidebar-show-btn):hover
  right:0px !important
  transition: 0.5 !important
</style>